<template>
  <div>
    <c-tab
      :dense="true"
      :tabItems.sync="tabItems"
      :height.sync="height"
      type="vertical"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :sopFireFightingDocumentClassCd="tab.sopFireFightingDocumentClassCd"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
export default {
  name: 'fireFightingDocumentTab',
  props: {
    gubun: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      tab: 'tab1',
      height: '',
      editable: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  computed: {
    tabItems() {
      let items = [];
      if (this.gubun === 'simulation') {
        items = [
          { name: 'tab1', label: '지도', component: () => import(`${'./fireFightingDocumentEditor.vue'}`),
            sopFireFightingDocumentClassCd: 'FFDC000004' },
          { name: 'tab2', label: '화재발생시 행동요령', component: () => import(`${'./fireFightingDocumentEditor.vue'}`),
            sopFireFightingDocumentClassCd: 'FFDC000005' },
          { name: 'tab3', label: '활동예시', component: () => import(`${'./fireFightingDocumentEditor.vue'}`),
            sopFireFightingDocumentClassCd: 'FFDC000006' },
          { name: 'tab4', label: '본관 1층 평면도', component: () => import(`${'./fireFightingDocumentEditor.vue'}`),
            sopFireFightingDocumentClassCd: 'FFDC000007' },
        ]
      } else if (this.gubun === 'self') {
        items = [
          { name: 'tab1', label: '조직도<br/>(본관)', component: () => import(`${'./fireFightingDocumentOrg.vue'}`),
            sopFireFightingDocumentClassCd: 'FFDC000008' },
          { name: 'tab2', label: '조직도<br/>(가공공장)', component: () => import(`${'./fireFightingDocumentOrg.vue'}`),
            sopFireFightingDocumentClassCd: 'FFDC000009' },
          { name: 'tab3', label: '조직도<br/>(조립공장)', component: () => import(`${'./fireFightingDocumentOrg.vue'}`),
            sopFireFightingDocumentClassCd: 'FFDC000010' },
          { name: 'tab4', label: '조직도<br/>(엔진공장)', component: () => import(`${'./fireFightingDocumentOrg.vue'}`),
            sopFireFightingDocumentClassCd: 'FFDC000011' },
          { name: 'tab5', label: '조직도<br/>(서비스/<br/>물류반/<br/>판금공장동/<br/>폐수처리장)', component: () => import(`${'./fireFightingDocumentOrg.vue'}`),
            sopFireFightingDocumentClassCd: 'FFDC000012' },
          { name: 'tab6', label: '야간 조직도', component: () => import(`${'./fireFightingDocumentOrg.vue'}`),
            sopFireFightingDocumentClassCd: 'FFDC000013' },
          { name: 'tab7', label: '행동요령', component: () => import(`${'./fireFightingDocumentEditor.vue'}`),
            sopFireFightingDocumentClassCd: 'FFDC000014' },
          { name: 'tab8', label: '업무분장', component: () => import(`${'./fireFightingDocumentEditor.vue'}`),
            sopFireFightingDocumentClassCd: 'FFDC000015' },
        ]
      }
      return items;
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
      this.setSize();
    },
    setSize() {
      let height = window.innerHeight - 150
      if (height < 600) {
        height = 600;
      }
      this.height = String(height) + 'px'
    },
  }
};
</script>